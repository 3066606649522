import "./App.css";
import Header from "./components/Header";
function App() {
  return (
    <div className="App">
    <Header/>
    </div>
  );
}

export default App;
